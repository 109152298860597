import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import SoftTissue from "../../images/SoftTissue.png";

const TissueMobilization = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>What is Soft Tissue Mobilization? | JointWorks PT</title>
        <meta
          name="description"
          content="Soft tissue mobilization improves flexibility, reduces pain, and speeds up recovery. Learn how hands-on techniques can enhance healing."
        />
        <meta
          name="keywords"
          content="soft tissue mobilization, physical therapy, manual therapy, muscle release, pain relief"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Soft Tissue Mobilization",
            description:
              "Soft tissue mobilization is a manual therapy technique that reduces muscle tightness, improves circulation, and relieves pain.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/soft-tissue-mobilization",
            },
          })}
        </script>
      </Helmet>
      <ServicePageTemplate
        title="Soft Tissue Mobilization"
        description="A hands-on technique to relieve muscle tightness and improve mobility."
        imageSrc={SoftTissue}
        faq={[
          {
            question: "What is soft tissue mobilization in physical therapy?",
            answer:
              "Soft tissue mobilization (STM) is a hands-on technique that applies pressure to muscles, fascia, and connective tissues to reduce pain, improve circulation, and enhance mobility.",
          },
          {
            question: "How does soft tissue mobilization help?",
            answer:
              "It releases muscle tension, breaks down scar tissue, reduces inflammation, and restores flexibility, making it effective for chronic pain, sports injuries, and post-surgical recovery.",
          },
          {
            question: "What conditions benefit from soft tissue mobilization?",
            answer:
              "It is used for tendonitis, muscle strains, post-operative stiffness, fibromyalgia, and conditions involving fascial restrictions or myofascial pain syndrome.",
          },
          {
            question: "Is soft tissue mobilization the same as massage?",
            answer:
              "While similar, STM is more targeted and medically guided, using specific techniques to address dysfunction rather than relaxation-based massage methods.",
          },
        ]}
      />
    </div>
  );
};

export default TissueMobilization;
