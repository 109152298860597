import React from "react";
import { Helmet } from "react-helmet-async";
import "./Steven.css";
import SJL from "../images/SJL.png";

const Steven = () => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Dr. Steven Lagasse | Orthopedic Physical Therapist</title>
        <meta
          name="description"
          content="Dr. Steven Lagasse is a Board-Certified Orthopedic Physical Therapist specializing in hands-on treatment, therapeutic exercise, and patient-centered care."
        />
        <meta
          name="keywords"
          content="Orthopedic Physical Therapy, Manual Therapy, Boston University Fellowship, Dry Needling, Joint Mobilization, Sports Rehabilitation"
        />
        <meta name="author" content="Dr. Steven Lagasse" />
      </Helmet>

      {/* Structured Data (JSON-LD) for Rich Search Results */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "MedicalBusiness",
          "name": "Dr. Steven Lagasse",
          "url": "https://www.jointworkspt.com",
          "image": "https://www.jointworkspt.com/path-to-SJL.png",
          "description":
            "Dr. Steven Lagasse is a Board-Certified Clinical Specialist in Orthopedic Physical Therapy, specializing in hands-on care, manual therapy, and therapeutic exercise.",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Northbridge",
            "addressRegion": "MA",
            "addressCountry": "US",
          },
          "founder": {
            "@type": "Person",
            "name": "Dr. Steven Lagasse",
          },
          "medicalSpecialty": "Orthopedic Physical Therapy",
        })}
      </script>

      <h1>Dr. Steven Lagasse, PT, DPT, OCS, CMPT, Cert DN</h1>

      <div className="taper-body-info">
        <div className="center-image-container">
          <img className="steve-face" alt="Dr. Steven Lagasse, Physical Therapist" src={SJL} />
        </div>

        <p className="bolded-titles">
          Steven is a Board-Certified Clinical Specialist in Orthopedic Physical Therapy, passionate about helping people improve their quality of life through expert hands-on treatment and individualized care.
        </p>

        <div className="center-image-container">
          <a className="body-button" href="/services">
            Learn more about services
          </a>
        </div>

        <h2 className="bolded-titles">Professional Background</h2>

        <p>
          Steven grew up in Northbridge, MA, and developed a passion for health and fitness at a young age. He earned his Bachelor of Science in Exercise Physiology and Health Fitness from Bridgewater State University in 2016. While at Bridgewater, he worked as a personal trainer and competed as a Bodybuilder and Olympic Weightlifter. After graduating, he went on to earn his Doctorate of Physical Therapy from the University of Connecticut in 2019.
        </p>

        <p>
          Steven completed his residency training at the Virginia Orthopedic Manual Physical Therapy Institute, where he specialized in orthopedic physical therapy. He also served as an adjunct faculty member at Shenandoah University’s physical therapy program, assisting with didactic coursework and student internships.
        </p>

        <p>
          After residency, Steven moved back to Massachusetts to work at Brigham and Women’s Hospital, treating a wide range of orthopedic conditions, including conservative injury management, post-surgical rehabilitation, and chronic pain. During this time, he earned certifications as a Manual Physical Therapist through The North American Institute of Orthopedic Manual Therapy and became a Board-Certified Clinical Specialist in Orthopedic Physical Therapy through the American Board of Physical Therapy Specialties.
        </p>

        <p>
          Currently, Steven is a fellow-in-training in <a href="https://www.bu.edu/ptfellowship/" target="_blank" rel="noopener noreferrer">Boston University's Fellowship in Orthopaedic Manual Physical Therapy</a>, further refining his expertise in advanced clinical reasoning, spine and extremity manipulation, and hands-on treatment techniques.
        </p>

        <p>
          Committed to providing the highest quality of care, Steven takes the time to listen to his patients' concerns and develop individualized treatment plans tailored to their specific needs. He offers private, one-on-one concierge physical therapy services, delivering a level of expertise that exceeds traditional physical therapy models.
        </p>
      </div>
    </>
  );
};

export default Steven;
