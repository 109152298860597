import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"


import Home from './pages/Home'
import Steven from './pages/Steven'
import Services from './pages/Services'
import Contact from './pages/Contact'
import Testimonials from './pages/Testimonials'
import Flyer from './pages/Flyer'
import InsuranceInfo from './pages/InsuranceInfo'
import PrivacyPractices from './pages/PrivacyPractices'
import NoSurprisesAct from './pages/NoSuprisesAct'
import Navbar from './components/Navbar'
import PreFooter from './components/PreFooter'
import CopyrightFooter from './components/CopyrightFooter'
import Payment from './pages/Payment'
import NewPatientPortal from './pages/NewPatientPortal'
import Telehealth from './pages/service-pages/Telehealth'
import MobilizationAndManipulation from './pages/service-pages/MobilizationAndManipulation'
import DryNeedling from './pages/service-pages/DryNeedling'
import TherapeuticExercise from './pages/service-pages/TherapeuticExercise'
import BloodFlowRestriction from './pages/service-pages/BloodFlowRestriction'
import TissueMobilization from './pages/service-pages/TissueMobilization'
import CuppingTherapy from './pages/service-pages/CuppingTherapy'
import './App.css'

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/about', element: <Steven /> },
  { path: '/services', element: <Services /> },
  { path: '/insurance', element: <InsuranceInfo /> },
  { path: '/contact', element: <Contact /> },
  { path: '/testimonials', element: <Testimonials /> },
  { path: '/flyer', element: <Flyer /> },
  { path: '/privacy-practices', element: <PrivacyPractices /> },
  { path: '/no-surprises-act', element: <NoSurprisesAct /> },
  { path: '/payment', element: <Payment /> },
  //everything below here are service-pages
  { path: '/Telehealth', element: <Telehealth /> },
  { path: '/mobilization-and-manipulation', element: <MobilizationAndManipulation /> },
  { path: '/dry-needling', element: <DryNeedling /> },
  { path: '/therapeutic-exercise', element: <TherapeuticExercise /> },
   { path: '/blood-flow-restriction', element: <BloodFlowRestriction /> },
  { path: '/tissue-mobilization', element: <TissueMobilization /> },
  { path: '/cupping-therapy', element: <CuppingTherapy /> },
  { path: '/new-patient-portal', element: <NewPatientPortal /> },
])

const App = () => {
  return (
    <div className="app-container">
      <Navbar />
      <RouterProvider router={router} />
      <PreFooter />
      <CopyrightFooter />
      <Analytics/>
      <SpeedInsights/>
    </div>
  )
}

export default App


// Colors
// #20a4a4 - Teal
// #08acec - Blue