import React from "react";
import { Helmet } from "react-helmet-async";
import "./Services.css";
import Needling from "../images/Adjunct.jpg";
import Manual from "../images/manualtherapy.png";
import Therex from "../images/Therex.jpg";
import HomePT from "../images/homept.png";
import BFR from "../images/BFR.png";
import Cupping from "../images/Cupping.png";
import SoftTissue from "../images/SoftTissue.png";

const Services = () => {
  const services = [
    {
      title: "In-Home & Telehealth Physical Therapy",
      description:
        "JointWorks brings physical therapy to you, whether in your home or through a virtual telehealth session. Receive expert care where you feel most comfortable. We specialize in post-surgical rehab, injury recovery, and chronic pain management.",
      infoLink: "/telehealth",
      image: HomePT,
      alt: "Physical therapist assisting a patient at home",
    },
    {
      title: "Joint Mobilization & Spinal Manipulation Therapy",
      description:
        "We use advanced joint mobilization and spinal manipulation techniques to reduce pain and restore movement. This hands-on therapy is effective for conditions such as neck pain, back pain, and joint stiffness.",
      infoLink: "/mobilization-and-manipulation",
      image: Manual,
      alt: "Physical therapist performing joint mobilization on a patient",
    },
    {
      title: "Dry Needling for Pain Relief & Muscle Recovery",
      description:
        "Dry Needling is an evidence-based technique that targets trigger points to reduce muscle tension and improve function. It’s highly effective for treating headaches, neck pain, sciatica, and sports injuries.",
      infoLink: "/dry-needling",
      image: Needling,
      alt: "Physical therapist performing dry needling on a patient's shoulder",
    },
    {
      title: "Rehab & Strength Training with Therapeutic Exercise",
      description:
        "Therapeutic exercise is the foundation of recovery. We create personalized rehab programs to improve strength, balance, and mobility, helping you recover from injuries and prevent future issues.",
      infoLink: "/therapeutic-exercise",
      image: Therex,
      alt: "Patient performing a resistance band exercise in physical therapy",
    },
    {
      title: "Soft Tissue Mobilization & Myofascial Release",
      description:
        "Our soft tissue techniques, including myofascial release and deep tissue mobilization, help relieve muscle tightness, reduce scar tissue, and restore range of motion.",
      infoLink: "/tissue-mobilization",
      image: SoftTissue,
      alt: "Physical therapist performing soft tissue mobilization on a patient's leg",
    },
    {
      title: "Blood Flow Restriction (BFR) Training for Strength & Recovery",
      description:
        "BFR Training enhances muscle strength and recovery by restricting blood flow during low-load exercise, making it ideal for post-surgical rehab, athletic recovery, and injury prevention.",
      infoLink: "/blood-flow-restriction",
      image: BFR,
      alt: "Patient using a BFR cuff during physical therapy exercises",
    },
    {
      title: "Functional Cupping Therapy for Pain & Mobility",
      description:
        "Functional Cupping uses targeted suction therapy to improve circulation, break up fascial adhesions, and enhance mobility. It’s effective for sports recovery, chronic pain, and tight muscles.",
      infoLink: "/cupping-therapy",
      image: Cupping,
      alt: "Physical therapist applying cupping therapy to a patient's back",
    },
  ];

  return (
    <>
      {/* SEO & Metadata */}
      <Helmet>
        <title>Physical Therapy Services | JointWorks PT</title>
        <meta
          name="description"
          content="Expert physical therapy services, including dry needling, spinal manipulation, therapeutic exercise, and more. Book your free consultation today!"
        />
        <meta property="og:title" content="Physical Therapy Services | JointWorks PT" />
        <meta
          property="og:description"
          content="Expert physical therapy services, including dry needling, spinal manipulation, therapeutic exercise, and more."
        />
        <meta property="og:url" content="https://www.jointworkspt.com/services" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.jointworkspt.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        
        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalClinic",
            "name": "JointWorks Physical Therapy",
            "url": "https://www.jointworkspt.com",
            "logo": "https://www.jointworkspt.com/logo.png",
            "description":
              "JointWorks PT provides expert physical therapy services, including dry needling, spinal manipulation, therapeutic exercise, and more.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1047 Providence Rd",
              "addressLocality": "Whitinsville",
              "addressRegion": "MA",
              "postalCode": "01588",
              "addressCountry": "US",
            },
            "telephone": "+1-774-246-6440",
            "sameAs": [
              "https://www.facebook.com/jointworkspt",
              "https://www.instagram.com/jointworkspt",
            ],
          })}
        </script>
      </Helmet>

      {/* SEO Optimized H1 */}
      <h1>Expert Physical Therapy Services</h1>

      <div className="container taper-body-info">
        {/* Free Consultation Section */}
        <div className="dashed-border">
          <h2 className="emphasize">Free Consultation – Get Started Today!</h2>
          <p>
            Struggling with pain or mobility issues? We offer a free phone or
            telehealth consultation to discuss your needs and determine the best
            treatment plan.
          </p>
          <a className="body-button" href="https://jointworkspt.janeapp.com">
            Schedule Your Free Consultation
          </a>
        </div>

        {/* Services List */}
        {services.map((service, index) => (
          <div key={index}>
            <h2>{service.title}</h2>
            <img alt={service.alt} src={service.image} />
            <p>
              {service.description}{" "}
              <a href={service.infoLink}>Learn more</a>.
            </p>
          </div>
        ))}
        <br />
        <a className="body-button" href="https://jointworkspt.janeapp.com">
          Book an Appointment
        </a>
      </div>
    </>
  );
};

export default Services;
