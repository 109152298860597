import React from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import TestimonialList from "../components/TestimonialList";
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <>
      {/* SEO Meta Data */}
      <Helmet>
        <title>Patient Testimonials | JointWorks PT</title>
        <meta
          name="description"
          content="See what our patients are saying about their experience with JointWorks PT. Read real testimonials from satisfied clients who achieved pain relief and better mobility."
        />
      </Helmet>

      <h1>See What Our Patients Are Saying</h1>
      <div className="taper-body-info">
        <TestimonialList />
        <div className="center-item-container">
          <a
            className="body-button margin-top"
            href="https://jointworkspt.janeapp.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Book an appointment with JointWorks PT"
          >
            Book an Appointment
          </a>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
