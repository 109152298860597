import React from "react";
import "./SocialMedia.css";

import greenFacebook from "../images/social-media/green-facebook.png";
import greenInstagram from "../images/social-media/green-instagram.png";
import greenTwitter from "../images/social-media/green-twitter.png";
import greenYoutube from "../images/social-media/green-youtube.png";
import blueFacebook from "../images/social-media/blue-facebook.png";
import blueInstagram from "../images/social-media/blue-instagram.png";
import blueTwitter from "../images/social-media/blue-twitter.png";
import blueYoutube from "../images/social-media/blue-youtube.png";

const SocialMedia = () => {
  return (
    <div className="social-media-container">
      <a
        className="social-media-icon"
        href="https://facebook.com/jointworkspt"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Follow JointWorks PT on Facebook"
      >
        <img alt="Facebook logo in green" src={greenFacebook} />
        <img alt="Facebook logo in blue" src={blueFacebook} />
      </a>
      <a
        className="social-media-icon"
        href="https://instagram.com/jointworkspt"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Follow JointWorks PT on Instagram"
      >
        <img alt="Instagram logo in green" src={greenInstagram} />
        <img alt="Instagram logo in blue" src={blueInstagram} />
      </a>
      <a
        className="social-media-icon"
        href="https://twitter.com/jointworkspt"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Follow JointWorks PT on Twitter (X)"
      >
        <img alt="Twitter logo in green" src={greenTwitter} />
        <img alt="Twitter logo in blue" src={blueTwitter} />
      </a>
      <a
        className="social-media-icon"
        href="https://youtube.com/@jointworkspt"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Subscribe to JointWorks PT on YouTube"
      >
        <img alt="YouTube logo in green" src={greenYoutube} />
        <img alt="YouTube logo in blue" src={blueYoutube} />
      </a>
    </div>
  );
};

export default SocialMedia;
