import React from "react";
import SocialMedia from "./SocialMedia";
import LegalFooter from "./LegalFooter";
import location from "../images/vectors/location.png";
import bellatorFitness from "../images/Bellator-Fitness.png";
import JTB from "../images/JTB.png";
import { Helmet } from "react-helmet-async";
import "./PreFooter.css";

const PreFooter = () => {
  return (
    <div className="prefooter">
      <Helmet>
        {/* Structured Data for Local Business */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "JointWorks PT",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1047 Providence Rd",
              "addressLocality": "Whitinsville",
              "addressRegion": "MA",
              "postalCode": "01588",
              "addressCountry": "US"
            },
            "url": "https://jointworkspt.com",
            "sameAs": [
              "https://www.facebook.com/jointworkspt",
              "https://www.instagram.com/jointworkspt"
            ]
          })}
        </script>
      </Helmet>

      <div className="pf1">
        {/* Original Bellator Fitness section */}
        <div className="address-and-logo-container">
          <div className="address-container">
            <div className="location-container">
              <img 
                alt="Location pin icon" 
                className="location-icon" 
                src={location} 
              />
              <h3 className="underline-text">Located Inside:</h3>
            </div>
            <span>1047 Providence Rd,</span>
            <span>Whitinsville, MA 01588</span>
          </div>
          <a 
            href="https://bellatorfitnessxxiv.com/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img 
              alt="Bellator Fitness gym logo - JointWorks PT location" 
              className="bellator-fitness-icon" 
              src={bellatorFitness} 
            />
          </a>
        </div>
        <br />

        {/* JTB Partnership section */}
        <div className="address-and-logo-container">
          <div className="address-container">
            <div className="location-container">
              <img 
                alt="Location pin icon" 
                className="location-icon" 
                src={location} 
              />
              <h3 className="underline-text">Partnered with:</h3>
            </div>
            <span>27 Mendon St,</span>
            <span>Uxbridge, MA 01569</span>
          </div>
          <a 
            href="https://jtbwellness.com/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img 
              alt="JTB Wellness studio logo - JointWorks PT partnership" 
              className="bellator-fitness-icon" 
              src={JTB} 
            />
          </a>
        </div>
        <br />
      </div>

      <div className="pf2">
        <SocialMedia />
        <br />
        <LegalFooter />
      </div>
    </div>
  );
};

export default PreFooter;
