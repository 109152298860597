import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import BFR from "../../images/BFR.png";

const BloodFlowRestriction = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>Blood Flow Restriction Therapy | JointWorks PT</title>
        <meta
          name="description"
          content="Blood Flow Restriction (BFR) therapy accelerates muscle growth and rehab with low-load training. Learn how it helps recovery."
        />
        <meta
          name="keywords"
          content="BFR therapy, blood flow restriction, muscle recovery, physical therapy, strength training"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Blood Flow Restriction Therapy",
            description:
              "BFR therapy enhances strength and recovery by restricting venous blood flow during low-load exercise, making rehab more effective.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/blood-flow-restriction",
            },
          })}
        </script>
      </Helmet>
      <ServicePageTemplate
        title="Blood Flow Restriction Therapy"
        description="A cutting-edge technique to enhance muscle strength and recovery with low loads."
        imageSrc={BFR}
        faq={[
          {
            question: "How does blood flow restriction therapy work?",
            answer:
              "BFR involves applying a specialized cuff to restrict venous blood flow while allowing arterial flow. This enables low-load strength training to produce high-intensity gains.",
          },
          {
            question: "What are the benefits of BFR therapy?",
            answer:
              "It enhances muscle growth, speeds up rehabilitation, preserves strength during injury recovery, and reduces joint stress, making it ideal for post-surgical patients and athletes.",
          },
          {
            question: "Is blood flow restriction therapy safe?",
            answer:
              "Yes, when supervised by a trained physical therapist. It is well-researched and widely used in sports medicine and rehabilitation for safe, effective muscle strengthening.",
          },
          {
            question: "How quickly do you see results with BFR therapy?",
            answer:
              "Results can be noticeable within a few weeks, as BFR stimulates muscle adaptation faster than traditional rehab exercises alone.",
          },
        ]}
      />
    </div>
  );
};

export default BloodFlowRestriction;
