import React from "react";
import { Link } from "react-router-dom"; // For navigation
import "../Services.css";

const ServicePageTemplate = ({ title, imageSrc, faq }) => {
  return (
    <div className="container taper-body-info">
      <h1>{title}</h1>
      <div>
        <img src={imageSrc} alt={title} />
        {/* <p>{description}</p> */}
      </div>

      {/* FAQ Section */}
      <div className="service-faq">
        <h2>Frequently Asked Questions</h2>
        {faq.map((item, index) => (
          <div key={index} className="faq-item">
            <h3 className="faq-question">{item.question}</h3>
            <p className="faq-answer">{item.answer}</p>
          </div>
        ))}
      </div>

      {/* Action Buttons */}
      <div className="center-item-container">
        <Link to="/services" className="body-button">
          Back to Services
        </Link>
      </div>
    </div>
  );
};

export default ServicePageTemplate;
