import React from "react";
import { Helmet } from "react-helmet-async";
import Insurance from "../images/Insurance.jpg";
import "./InsuranceInfo.css";

const InsuranceInfo = () => {
  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>Transparent Pricing & Insurance | JointWorks Physical Therapy</title>
        <meta
          name="description"
          content="JointWorks Physical Therapy offers straightforward, competitive pricing. We provide superbills for potential insurance reimbursement. Learn more about our self-pay model."
        />
        <meta
          name="keywords"
          content="physical therapy pricing, cash-based PT, out-of-network PT, insurance reimbursement, self-pay physical therapy, superbill for PT, dry needling cost"
        />

        {/* Open Graph for Social Media Sharing */}
        <meta property="og:title" content="Transparent Pricing & Insurance | JointWorks PT" />
        <meta
          property="og:description"
          content="At JointWorks PT, we prioritize one-on-one care with clear pricing. Learn about our physical therapy packages & reimbursement options."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jointworkspt.com/insurance" />
        <meta property="og:image" content="https://www.jointworkspt.com/images/Insurance.jpg" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalBusiness",
            "name": "JointWorks Physical Therapy",
            "url": "https://www.jointworkspt.com",
            "image": "https://www.jointworkspt.com/images/Insurance.jpg",
            "description": "JointWorks PT offers expert care with transparent pricing. We provide superbills for potential insurance reimbursement. Learn about our self-pay model and package discounts.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1047 Providence Rd",
              "addressLocality": "Whitinsville",
              "addressRegion": "MA",
              "postalCode": "01588",
              "addressCountry": "US"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-774-246-6440",
              "contactType": "customer service"
            },
            "email": "Dr.Lagasse@JointWorksPT.com",
            "priceRange": "$$",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Wednesday", "Friday", "Saturday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "availableService": [
              {
                "@type": "MedicalProcedure",
                "name": "Physical Therapy Evaluation",
                "price": "$190",
                "url": "https://www.jointworkspt.com/insurance"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Follow-up Physical Therapy Session",
                "price": "$190",
                "url": "https://www.jointworkspt.com/insurance"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Dry Needling Session (45 min)",
                "price": "$150",
                "url": "https://www.jointworkspt.com/insurance"
              },
              {
                "@type": "Offer",
                "name": "Essential Package (Initial Eval + 3 Sessions)",
                "price": "$684",
                "discount": "10% off",
                "url": "https://www.jointworkspt.com/insurance"
              },
              {
                "@type": "Offer",
                "name": "Comprehensive Package (Initial Eval + 7 Sessions)",
                "price": "$1,216",
                "discount": "20% off",
                "url": "https://www.jointworkspt.com/insurance"
              },
              {
                "@type": "Offer",
                "name": "Rapid Relief Dry Needling Package",
                "price": "$540",
                "discount": "10% off",
                "url": "https://www.jointworkspt.com/insurance"
              }
            ]
          })}
        </script>
      </Helmet>

      <h1>Transparent Pricing & Insurance Options</h1>
      <div className="container taper-body-info">
        <div className="center-item-container">
          <img alt="JointWorks PT Pricing and Insurance" className="insurance-img" src={Insurance} />
        </div>
        <div className="taper-body-info">
          <div className="paragraph-container">
            <p>
              At JointWorks, we prioritize high-quality, one-on-one care with clear and competitive pricing. Our direct-pay model allows us to focus entirely on your recovery without insurance limitations.
            </p>
            <p>
              While we are a self-pay practice, we provide detailed superbills for potential insurance reimbursement. We recommend checking with your provider before your visit, as reimbursement is managed directly between you and your insurance provider.
            </p>

            <div className="paragraph-price-container">
              <hr />
              <h2 className="black-underline">Individual Session Pricing</h2>
              <p>
                <strong>60-minute Initial Evaluation:</strong> $190
              </p>
              <p>
                <strong>60-minute Follow-up Session:</strong> $190
              </p>
              <p>
                <strong>45-minute Dry Needling Session:</strong> $150*
              </p>
              <i>*Superbills are not available for dry needling-only sessions.</i>

              <h2 className="black-underline">Physical Therapy Packages</h2>

              <div className="pricing-margin">
                <p>
                  <strong className="promotion-price-10">Essential Package:</strong>{" "}
                  <span className="discounted">$760</span> - Now $684 (Save 10%)
                </p>
                <i>Includes Initial Eval + 3 Follow-up Sessions</i>
              </div>

              <div className="pricing-margin">
                <p>
                  <strong className="promotion-price-20">Comprehensive Package:</strong>{" "}
                  <span className="discounted">$1,520</span> - Now $1,216 (Save 20%)
                </p>
                <i>Includes Initial Eval + 7 Follow-up Sessions</i>
              </div>

              <h2 className="black-underline">Dry Needling Package</h2>
              <div className="pricing-margin">
                <p>
                  <strong className="bfr-price">Rapid Relief Package:</strong>{" "}
                  <span className="discounted">$600</span> - Now $540 (Save 10%)
                </p>
                <i>Includes Initial Eval + 3 Dry Needling Sessions</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceInfo;
