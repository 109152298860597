import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import Manual from "../../images/manualtherapy.png";

const MobilizationAndManipulation = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>Joint Mobilization & Manipulation | JointWorks PT</title>
        <meta
          name="description"
          content="Restore mobility and reduce pain with joint mobilization and manipulation. Learn how these hands-on techniques improve movement and function."
        />
        <meta
          name="keywords"
          content="joint mobilization, physical therapy, manual therapy, joint manipulation, pain relief"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Joint Mobilization & Manipulation",
            description:
              "Hands-on techniques that restore movement and alleviate joint stiffness in the spine, shoulders, hips, and more.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/mobilization-and-manipulation",
            },
          })}
        </script>
      </Helmet>

      <ServicePageTemplate
        title="Mobilization & Manipulation"
        description="Hands-on techniques to improve joint mobility and reduce pain."
        imageSrc={Manual}
        faq={[
          {
            question:
              "What is the difference between mobilization and manipulation?",
            answer:
              "Mobilization involves slow, controlled joint movements to improve mobility, while manipulation is a quick thrust technique used to restore movement and reduce pain.",
          },
          {
            question: "What conditions can joint mobilization help?",
            answer:
              "It is beneficial for arthritis, joint stiffness, post-injury recovery, neck and back pain, and limited range of motion in areas like shoulders, hips, and ankles.",
          },
          {
            question: "Is joint manipulation safe?",
            answer:
              "Yes, when performed by a trained physical therapist. It is a safe and effective method to restore mobility and relieve pain, though some mild soreness may occur post-treatment.",
          },
          {
            question: "How quickly does joint manipulation work?",
            answer:
              "Many patients feel immediate relief after treatment, but lasting improvements typically require multiple sessions combined with exercise and movement retraining.",
          },
        ]}
      />
    </div>
  );
};

export default MobilizationAndManipulation;
