import React from "react";
import { Helmet } from "react-helmet-async";
import clinic from "../images/clinic.jpg";
import "./Home.css";

const Home = () => {
  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>
          JointWorks PT | Expert Physical Therapy in Northbridge, MA
        </title>
        <meta
          name="description"
          content="JointWorks Physical Therapy offers expert, one-on-one care without insurance barriers. Start your recovery today with Dr. Steven Lagasse in Northbridge, MA."
        />
        <meta
          name="keywords"
          content="physical therapy Northbridge MA, out-of-network PT, JointWorks PT, manual therapy, sports rehab, dry needling, back pain relief, movement therapy"
        />

        {/* Open Graph for Social Media Sharing */}
        <meta
          property="og:title"
          content="JointWorks PT | Expert Physical Therapy"
        />
        <meta
          property="og:description"
          content="Personalized physical therapy for pain relief, sports recovery, and movement optimization in Northbridge, MA. Book your consultation today!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jointworkspt.com" />
        <meta
          property="og:image"
          content="https://www.jointworkspt.com/images/clinic.jpg"
        />

        {/* JSON-LD Structured Data for Local Business */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "PhysicalTherapy",
            name: "JointWorks Physical Therapy",
            url: "https://www.jointworkspt.com",
            image: "https://www.jointworkspt.com/images/clinic.jpg",
            description:
              "Expert, one-on-one physical therapy focused on getting results—no insurance barriers, just care tailored to you.",
            address: {
              "@type": "PostalAddress",
              streetAddress: "1047 Providence Rd",
              addressLocality: "Whitinsville",
              addressRegion: "MA",
              postalCode: "01588",
              addressCountry: "US",
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: "42.1138",
              longitude: "-71.6484",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-774-246-6440",
              contactType: "customer service",
            },
            email: "Dr.Lagasse@JointWorksPT.com",
            priceRange: "$$",
            openingHoursSpecification: [
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: ["Monday", "Wednesday", "Friday", "Saturday"],
                opens: "08:00",
                closes: "18:00",
              },
            ],
            sameAs: [
              "https://www.instagram.com/jointworkspt",
              "https://www.facebook.com/jointworkspt",
            ],
            availableService: [
              {
                "@type": "MedicalProcedure",
                name: "Manual Therapy",
                url: "https://www.jointworkspt.com/services",
              },
              {
                "@type": "MedicalProcedure",
                name: "Dry Needling",
                url: "https://www.jointworkspt.com/services",
              },
              {
                "@type": "MedicalProcedure",
                name: "Spinal Manipulation",
                url: "https://www.jointworkspt.com/services",
              },
            ],
          })}
        </script>
      </Helmet>

      <h1>Welcome to JointWorks Physical Therapy!</h1>

      <div className="center-item-container">
        <img
          alt="JointWorks Physical Therapy clinic in Northbridge, MA"
          className="insurance-img"
          src={clinic}
        />
      </div>

      <div className="taper-body-info">
        <div className="paragraph-container">
          <p>
            Expert physical therapy tailored to your needs—no insurance
            barriers, just results.
          </p>
          <p>
            At JointWorks, your well-being comes first. We offer direct access,
            meaning you can start care quickly—no referral needed. As an
            out-of-network, self-pay practice, we focus entirely on you, without
            delays or denials from insurance companies.
          </p>
          <p>
            Looking for effective relief and personalized care?{" "}
            <a href="/services">See our treatment options</a> or set up a free
            consultation with Dr. Steven Lagasse, our expert physical therapist
            in Whitinsville, MA.
          </p>
        </div>

        <div className="center-item-container">
          <h3 className="underline-text">
            Your journey to pain-free movement starts here!
          </h3>
        </div>
        <br />
        <div className="center-item-container">
          <a className="body-button" href="https://jointworkspt.janeapp.com">
            Schedule Your Free Consultation
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
