import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import Cupping from "../../images/Cupping.png";

const CuppingTherapy = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>What is Cupping Therapy? | JointWorks PT</title>
        <meta
          name="description"
          content="Cupping therapy promotes circulation, reduces muscle tension, and enhances recovery. Learn how this ancient technique can improve mobility."
        />
        <meta
          name="keywords"
          content="cupping therapy, physical therapy, muscle recovery, pain relief, myofascial release"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Cupping Therapy",
            description:
              "Cupping therapy uses suction to improve blood flow, relieve muscle tension, and enhance healing for pain and stiffness.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/cupping-therapy",
            },
          })}
        </script>
      </Helmet>
      <ServicePageTemplate
        title="Cupping Therapy"
        description="A therapy technique that improves circulation, reduces pain, and enhances mobility."
        imageSrc={Cupping}
        faq={[
          {
            question: "How does cupping therapy work?",
            answer:
              "Cupping therapy uses suction cups to create negative pressure on the skin, increasing circulation, promoting healing, and reducing muscle tension and inflammation.",
          },
          {
            question: "What are the benefits of cupping therapy?",
            answer:
              "It helps with pain relief, muscle recovery, flexibility, and relaxation. It also reduces tightness, improves blood flow, and supports overall tissue health.",
          },
          {
            question: "Does cupping therapy leave marks?",
            answer:
              "Yes, temporary circular marks may appear due to increased blood flow but typically fade within a few days without pain or bruising.",
          },
          {
            question: "Who can benefit from cupping therapy?",
            answer:
              "Athletes, individuals with chronic pain, and those recovering from muscle injuries often find relief with cupping, as it helps restore tissue mobility.",
          },
        ]}
      />
    </div>
  );
};

export default CuppingTherapy;
