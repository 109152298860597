import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import Needling from "../../images/Adjunct.jpg"; // Import image

const DryNeedling = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>What is Dry Needling? | JointWorks PT</title>
        <meta
          name="description"
          content="Dry needling relieves muscle tension and pain by targeting trigger points. Learn about its benefits, safety, and effectiveness."
        />
        <meta
          name="keywords"
          content="dry needling, physical therapy, trigger point therapy, muscle pain relief, sports rehab"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Dry Needling",
            description:
              "Dry needling is a physical therapy technique that targets muscle trigger points to relieve pain and improve mobility.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            areaServed: "Northbridge, MA",
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/dry-needling",
            },
          })}
        </script>
      </Helmet>

      <ServicePageTemplate
        title="Dry Needling"
        imageSrc={Needling}
        faq={[
          {
            question: "How does dry needling work?",
            answer:
              "Dry needling involves inserting thin, monofilament needles into trigger points within muscles. This helps release tension, improve blood flow, and reduce pain by targeting muscular dysfunction and promoting natural healing.",
          },
          {
            question: "What conditions does dry needling treat?",
            answer:
              "It is effective for muscle pain, tightness, trigger points, headaches, tendonitis, and chronic conditions like fibromyalgia. It’s commonly used for sports injuries, neck and back pain, and post-surgical rehabilitation.",
          },
          {
            question: "Does dry needling hurt?",
            answer:
              "Most patients feel minimal discomfort, often describing the sensation as a mild twitch or pressure. Some soreness may occur post-treatment, similar to a workout, but it typically resolves within 24-48 hours.",
          },
          {
            question: "How is dry needling different from acupuncture?",
            answer:
              "Acupuncture is based on traditional Chinese medicine and energy flow, while dry needling is rooted in Western musculoskeletal science, focusing on relieving muscle knots and dysfunction.",
          },
        ]}
      />
    </div>
  );
};

export default DryNeedling;
