import React from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "../components/ContactForm";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Contact JointWorks PT | Expert Physical Therapy in Whitinsville, MA</title>
        <meta name="description" content="Get in touch with JointWorks Physical Therapy for expert one-on-one care. Visit our clinic, email, or call us to book your free consultation today." />
        <meta name="keywords" content="contact physical therapy, JointWorks PT, Whitinsville MA, Bellator Fitness, free consultation, expert care" />
        <meta property="og:title" content="Contact JointWorks PT | Expert Physical Therapy" />
        <meta property="og:description" content="Reach out to JointWorks PT for top-tier physical therapy care. Call, email, or visit us in Whitinsville, MA." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jointworkspt.com/contact" />
        <meta property="og:image" content="https://www.jointworkspt.com/images/contact-banner.jpg" />

        {/* JSON-LD Structured Data for Local Business */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "PhysicalTherapy",
            "name": "JointWorks Physical Therapy",
            "url": "https://www.jointworkspt.com",
            "image": "https://www.jointworkspt.com/images/logo.png",
            "description": "Expert one-on-one physical therapy services in Whitinsville, MA, specializing in orthopedic rehabilitation and movement optimization.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1047 Providence Rd",
              "addressLocality": "Whitinsville",
              "addressRegion": "MA",
              "postalCode": "01588",
              "addressCountry": "US"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-774-246-6440",
              "contactType": "customer service"
            },
            "email": "Dr.Lagasse@JointWorksPT.com",
            "priceRange": "$$",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Wednesday", "Friday", "Saturday"],
                "opens": "08:00",
                "closes": "18:00"
              }
            ],
            "sameAs": [
              "https://www.instagram.com/jointworkspt",
              "https://www.facebook.com/jointworkspt"
            ]
          })}
        </script>
      </Helmet>

      <h1>Questions? Contact Us Today!</h1>
      <div className="taper-body-info">
        <p>
          Choosing the right physical therapist is an important decision. At JointWorks, we make it easy by offering personalized, one-on-one care.
        </p>
        <p>
          If you're interested in a free consultation and movement screen, have questions about our services, or want to discuss your specific needs, reach out today!
        </p>
        <p>
          You can contact us through the form below, email, or phone—we’re happy to help.
        </p>

        <div>
          <div className="contact-container">
            <h3>Visit Our Clinic Inside Bellator Fitness:</h3>
            <p className="para-padding">
              <a
                href="https://maps.google.com/?q=1047+Providence+Rd,+Whitinsville,+MA+01588"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Get directions to JointWorks PT in Whitinsville, MA"
              >
                1047 Providence Rd, Whitinsville, MA 01588
              </a>
            </p>
          </div>
          <div className="contact-container">
            <h3>Email Us:</h3>
            <p className="para-padding">
              <a href="mailto:Dr.Lagasse@JointWorksPT.com" aria-label="Email JointWorks PT">
                Dr.Lagasse@JointWorksPT.com
              </a>
            </p>
          </div>
          <div className="contact-container">
            <h3>Call or Text:</h3>
            <p className="para-padding">
              <a href="tel:+17742466440" aria-label="Call JointWorks PT at (774) 246-6440">
                (774) 246-6440
              </a>
            </p>
          </div>
          <br />
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact;
