import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import HomePT from "../../images/homept.png";

const Telehealth = () => {
  return (
    <div>
      <Helmet>
        <title>Telehealth Physical Therapy | JointWorks PT</title>
        <meta
          name="description"
          content="Receive expert physical therapy care from the comfort of your home via secure video sessions. Access personalized rehabilitation anywhere."
        />
        <meta
          name="keywords"
          content="telehealth physical therapy, virtual PT, remote physical therapy, online therapy, telemedicine, rehabilitation at home"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Telehealth Physical Therapy",
            description:
              "Telehealth Physical Therapy offers remote, video-based rehabilitation, assessments, and exercise programs designed to support recovery and improve mobility from the comfort of home.",
            address: {
              "@type": "PostalAddress",
              streetAddress: "1047 Providence Rd",
              addressLocality: "Whitinsville",
              addressRegion: "MA",
              postalCode: "01588",
              addressCountry: "US",
            },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/telehealth",
            },
          })}
        </script>
      </Helmet>
      <ServicePageTemplate
        title="Telehealth Physical Therapy"
        description="Receive expert physical therapy care from the comfort of your home via secure video sessions."
        imageSrc={HomePT}
        faq={[
          {
            question: "How does telehealth physical therapy work?",
            answer:
              "Telehealth PT provides virtual assessments, movement guidance, and home exercise programs via video calls, allowing patients to receive expert care remotely.",
          },
          {
            question: "What are the benefits of telehealth physical therapy?",
            answer:
              "It offers convenient access to care, eliminates travel time, allows real-time exercise feedback, and supports ongoing rehabilitation from home.",
          },
          {
            question:
              "Can telehealth physical therapy be as effective as in-person care?",
            answer:
              "Yes, for many conditions. Virtual PT provides structured guidance, personalized exercise programs, and education, helping patients recover efficiently.",
          },
          {
            question: "Who is telehealth physical therapy best for?",
            answer:
              "It’s ideal for individuals with busy schedules, mobility limitations, or those in remote areas who need expert PT without visiting a clinic.",
          },
        ]}
      />
    </div>
  );
};

export default Telehealth;
