import React from "react";
import ServicePageTemplate from "./ServicePageTemplate";
import { Helmet } from "react-helmet-async";
import Therex from "../../images/Therex.jpg";

const TherapeuticExercise = () => {
  return (
    <div className="service-page">
      <Helmet>
        <title>What is Therapeutic Exercise? | JointWorks PT</title>
        <meta
          name="description"
          content="Therapeutic exercise improves strength, mobility, and recovery from injuries. Learn how a customized PT program can help you heal and perform better."
        />
        <meta
          name="keywords"
          content="therapeutic exercise, physical therapy, injury recovery, strength training, rehab exercises"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalProcedure",
            name: "Therapeutic Exercise",
            description:
              "Therapeutic exercise involves customized movements to restore function, improve strength, and reduce pain after injury or surgery.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1047 Providence Rd",
                addressLocality: "Whitinsville",
                addressRegion: "MA",
                postalCode: "01588",
                addressCountry: "US",
              },
            provider: {
              "@type": "MedicalClinic",
              name: "JointWorks PT",
              url: "https://www.jointworkspt.com/therapeutic-exercise",
            },
          })}
        </script>
      </Helmet>
      <ServicePageTemplate
        title="Therapeutic Exercise"
        description="Customized exercises to improve strength, flexibility, and overall function."
        imageSrc={Therex}
        faq={[
          {
            question: "What is therapeutic exercise in physical therapy?",
            answer:
              "Therapeutic exercise consists of targeted movements designed to improve strength, flexibility, balance, and function, tailored to a patient’s specific condition and goals.",
          },
          {
            question: "Who benefits from therapeutic exercise?",
            answer:
              "Anyone recovering from injury, surgery, or chronic pain conditions like arthritis, back pain, and muscle imbalances can benefit from a structured therapeutic exercise program.",
          },
          {
            question:
              "How is therapeutic exercise different from regular exercise?",
            answer:
              "Unlike general workouts, therapeutic exercises are customized, progressive, and designed to restore function while preventing further injury under professional guidance.",
          },
          {
            question: "Can therapeutic exercise help prevent injuries?",
            answer:
              "Yes, it improves muscle control, joint stability, and movement efficiency, reducing the risk of strains, sprains, and overuse injuries.",
          },
        ]}
      />
    </div>
  );
};

export default TherapeuticExercise;
